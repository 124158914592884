import axios from "axios";

export default function createHttp(token) {
    if (token) {
        return axios.create({
            headers: { "Authorization": `bearer ${token}` }
        });
    } else {
        return axios.create();
    }
}
