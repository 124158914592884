/**
 * Created by Andrey Popov on 12/9/20.
 */

// eslint-disable-next-line
import "../../../common/gamecontext";
import { createApp } from "vue";
import axios from "axios";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import { store } from "@/store";
import App from "./router/app.vue";
import router from "./router";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

const app = createApp(App);
app.use(PerfectScrollbar);

app.use(store);
app.use(router);
app.mount("#app");

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
