/**
 * Created by Andrey Popov on 12/16/20.
 */

const localhost = [{
    name: "Mergecraft_local",
    url: "http://localhost:3106",
    ws: "ws://localhost:4107"
}/* , {
    name: 'Riddles_local',
    url: 'http://localhost:3107',
    ws: 'ws://localhost:4108'
} */];

const production = [{
    name: "Riddles",
    url: "https://crm.cleverappssg.com/riddles"
}, {
    name: "Heroes",
    url: "https://crm.cleverappssg.com/heroes"
}, {
    name: "Runes",
    url: "https://crm.cleverappssg.com/runes"
}, {
    name: "Adventure",
    url: "https://crm.cleverappssg.com/adventure"
}, {
    name: "Wordsoup",
    url: "https://crm.cleverappssg.com/wordsoup"
}, {
    name: "Scramble",
    url: "https://crm.cleverappssg.com/scramble"
}, {
    name: "Crocword",
    url: "https://crm.cleverappssg.com/crocword"
}, {
    name: "Olympics",
    url: "https://crm.cleverappssg.com/olympics"
}, {
    name: "Tripeaks",
    url: "https://crm.cleverappssg.com/tripeaks"
}, {
    name: "Differences",
    url: "https://crm.cleverappssg.com/differences"
}, {
    name: "Mergecraft",
    url: "https://crm.cleverappssg.com/mergecraft"
}, {
    name: "Magicwords",
    url: "https://crm.cleverappssg.com/magicwords"
}, {
    name: "Wondermerge",
    url: "https://crm.cleverappssg.com/wondermerge"
}, {
    name: "Fairy",
    url: "https://crm.cleverappssg.com/fairy"
}, {
    name: "Spades",
    url: "https://crm.cleverappssg.com/spades"
}, {
    name: "Woodenblock",
    url: "https://crm.cleverappssg.com/woodenblock"
}, {
    name: "Zenmatch",
    url: "https://crm.cleverappssg.com/zenmatch"
}, {
    name: "Hustlemerge",
    url: "https://crm.cleverappssg.com/hustlemerge"
}];

const staging = [{
    name: "Mergecraft_staging",
    url: "https://mergecraft.labsystech.ru/crm-staging"
}, {
    name: "Wondermerge_staging",
    url: "https://wondermerge.labsystech.ru/crm-staging"
}, {
    name: "Fairy_staging",
    url: "https://fairy.labsystech.ru/crm-staging"
}, {
    name: "Tripeaks_staging",
    url: "https://riddles-staging.labsystech.ru/crm-staging"
}, {
    name: "Crocword_staging",
    url: "https://crocword-staging.labsystech.ru/crm-staging"
}/* , {
    name: "Differences_staging",
    url: "http://differences.labsystech.ru:3104"
}, {
    name: "Wordsoup_staging",
    url: "http://wordsoup-staging.labsystech.ru:3100"
} */];

const folders = [{
    name: "$Inbox",
    icon: "inbox_payers"
}, {
    name: "Inbox",
    icon: "inbox"
}, {
    name: "Spam",
    icon: "spam"
}, {
    name: "Starred",
    icon: "star-on"
}];

const infoTabs = [{ title: "Profile", value: "profile" },
    { title: "Game", value: "gameInfo" },
    { title: "Subs", value: "subscriptions" }];

const languages = ["de", "en", "ru", "es", "fr", "it", "ja", "nl", "pt", "ar", "ko", "tr", "zh", "pl", "lv"];

const sources = ["amazon", "android", "draugiem", "facebook", "fotostrana", "instant", "ios", "mbga",
    "sp_mbga", "mm", "ok", "vk", "gdcom", "coolmath", "plng", "plinga", "xsolla", "cleverapps", "yandex",
    "iyodo1", "microsoft", "macos", "kongregate", "msstart", "xiaomi", "mygames", "crazy", "samsung", "ton", "rustore"];

const respondingPersons = ["me", "Anna", "Mikhail", "Anastasia", "Vsevolod", "Bogdan", "Dmitriy", "Alexandrina",
    "Maria Naumova", "Artem Vinokurov", "Denis", "Sergei", "Natalia Sadovaya", "Arseniy Morozov"];

function getGameFamilies(Families) {
    Families.initialize();
    return Families.codes
        .filter((code) => (!UnitsLibrary.HIDDEN_CODES.concat(UnitsLibrary.HIDDEN_FRESH_CODES).includes(code) || ["landmark"].includes(code))
            && !UnitsLibrary.HIDDEN_TYPES.concat(UnitsLibrary.HIDDEN_FRESH_TYPES).includes(Families[code].type)
            && code !== "barrel" && code !== "unknown"
            && typeof Families[code] === "object")
        .map((code) => ({
            code,
            type: Families[code].type,
            units: Families[code].units,
            expeditions: Families[code].expeditions
        }));
}

const families = {
    mergecraft: getGameFamilies(require("../../../src/mergecraft/families")),
    wondermerge: getGameFamilies(require("../../../src/wondermerge/families")),
    hustlemerge: getGameFamilies(require("../../../src/hustlemerge/families")),
    fairy: getGameFamilies(require("../../../src/fairy/families"))
};

export default {
    games: process.env.VUE_APP_MODE === "production" && production || process.env.VUE_APP_MODE === "staging" && staging || localhost,
    folders,
    families,
    sources,
    respondingPersons,
    languages,
    infoTabs
};
