/**
 * Created by Vladislav on 01.08.2024.
 */

const Context = require("./context");

let globalObject;
if (typeof global !== "undefined") {
    globalObject = global;
} else {
    globalObject = window;
}

globalObject.bundles = {};
globalObject.connector = {};
globalObject.cleverapps = {
    Environment: {},
    Availables: {
        CLANS: {}
    }
};

const config = JSON.parse(JSON.stringify(Context.getConfig() || {}));
config.features = config.features || [];
globalObject.cleverapps.config = config;

globalObject.GitManager = function () {};
globalObject.CustomSyncers = {
    registerBySlots: function () {}
};
globalObject.TravelBook = {};
globalObject.Map2d = {};
globalObject.GuideWindow = {};
globalObject.ExpeditionWandsGuideOptions = {};
globalObject.ShopWindow = {
    TABS: {}
};
globalObject.RewardsConfig = {};
globalObject.Forces = {};

globalObject.DataLoader = require("../utils/dataloader");

require("../utils/utils");
require("../utils/push/localpushes");
require("../utils/push/localpushessenders");
require("../utils/push/yandexpushesmap");
require("../utils/abtest/abtest");
require("../utils/payments/paymentshistory");
require("../utils/lives/lives");

globalObject.Mission = require("../utils/missions/mission").Mission;
globalObject.Missions = require("../utils/missions/missions").Missions;

globalObject.UnitsLibrary = require("../levels/game/merge/unitslibrary");

const { ChestCreatePrize, ChestListPrize, ChestEnergyPrize } = require("../levels/game/merge/config/chestprizes");

globalObject.ChestCreatePrize = ChestCreatePrize;
globalObject.ChestListPrize = ChestListPrize;
globalObject.ChestEnergyPrize = ChestEnergyPrize;

globalObject.RecipesIndex = require("../levels/game/merge/config/recipes").RecipesIndex;
globalObject.Recipes = require("../levels/game/merge/config/recipes").Recipes;

require("../utils/pseudorandom");

globalObject.UnitSyncer = require("../levels/game/merge/sync/unitsyncer");
globalObject.Base80 = require("../utils/base80");
globalObject.PropertiesPacker = require("../levels/game/merge/sync/propertiespacker");

require("../utils/travel_book/travelbookpages");

globalObject.DeletedFamilies = require("../src/hustlemerge/hustlemergefeaturesconfig");

globalObject.Families = require("../levels/game/merge/config/families");
globalObject.FamiliesHelper = require("../levels/game/merge/config/familieshelper");

Families.initialize();