<template>
    <div class="header-title">
        <div v-if="userCard.users">
            <Icon v-if="userCard.users && userCard.users.length > 0" :name="userCard.users[0].source" :title=" userCard.users[0].source" />
            <Icon v-if="payerClass > 0" :title="payerClass" name="inbox_payers" />
            <img v-if="userCard.settings && userCard.settings.length > 0" class="flag-icon" :title='userCard.settings[0].language' :src="getFlagUrl(userCard.settings[0].language)"/>
        </div>
        <div class="icon-container">
            <img v-if="userAvatar" class="avatar" :src="userAvatar">
            <Icon v-if="!userAvatar" class="avatar" name="default_avatar" />
            <img :title="visitedAgo" class='status-circle' :class="onlineStatus">
        </div>
        <p class="header-title-text">{{ userName }}</p>
        <a :href="gameLink" :title="gameLevelWithEpisode" target="_blank">{{gameLevel}}</a>
        <div class="game-icon-container">
            <Icon class="game-icon" :title="gameName" :name="gameName.split('_')[0].toLowerCase()" />
            <Icon v-if="!music" name="music-off" class="music-icon"/>
            <Icon v-if="music" name="music-on" class="music-icon"/>
            <Icon v-if="!sound" name="sound-off" class="sound-icon"/>
            <Icon v-if="sound" name="sound-on" class="sound-icon"/>
        </div>
        <div v-if="abTests && abTests.length > 0" >AB tests:</div>
        <div class="small-text" v-for="test in abTests">{{ test }}</div>
    </div>

</template>

<script>

import Icon from '@/controls/icon.vue';
import moment from "moment";
import config from "@/config.js";
import utils from "@/utils.js";

export default {
    name: "HeaderInfo",
    components: {
        Icon
    },
    computed: {
        userCard() {
            return this.$store.state.userCard ? this.$store.state.userCard : {};
        },
        userName() {
            return this.userCard.userNames && this.userCard.userNames.length > 0 ? this.userCard.userNames[0].name : "<NONAME>";
        },
        gameLink() {
          var gameName = this.gameName.toLowerCase();
          var extraParams = '';
          if (this.$route.params.folder.indexOf('_staging') !== -1) {
            gameName = gameName.replace('_staging', '');
            extraParams = '?stand=true';
          }

          var fullGameName = this.$route.params.folder.split(":")[1];
          if (fullGameName.indexOf("_wechat") !== -1) {
              var gameConfig = config.games.filter(function (game) {
                  return game.name === fullGameName;
              })[0];

              if (gameConfig && gameConfig.url) {
                  extraParams = '?server=' + gameConfig.url.split("/")[2];
              }
          }

          var gameNameInUrl = ["crocword", "magicwords", "olympics", "riddles", "scramble", "wordsoup"].indexOf(gameName) !== -1 ? gameName + "-staging" : gameName;
          return "https://" + gameNameInUrl + ".labsystech.ru/" + gameName + "-rest/snapshots/import/" + encodeURIComponent(this.$route.params.userId) + extraParams;
        },
        gameName() {
            return this.$route.params.folder.split(":")[1].split("_")[0].toLowerCase();
        },
        gameLevel() {
            if (!this.userCard.users || !this.userCard.users[0]) {
              return '';
            }
            var data = this.userCard.users[0];

          var level = (parseInt(data.episode) * 15 + parseInt(data.level) + 1);

            if (data.progress) {
                return "Level " + (level + data.progress) + " = " + " " + utils.getRealLevel(data.episode, data.progress);
            }

            return "Level " + level;
        },
        gameLevelWithEpisode() {
            if (!this.userCard.users || !this.userCard.users[0]) {
                return '';
            }
            return "Episode: " + this.userCard.users[0].episode + " Level: " + this.userCard.users[0].level;
        },
        userAvatar() {
            return this.userCard.userNames && this.userCard.userNames.length > 0 && this.userCard.userNames[0].avatar &&
                this.userCard.userNames[0].avatar[0] !== '#' ? this.userCard.userNames[0].avatar : "";
        },
        payerClass() {
            return utils.classifyPayer(this.userCard);
        },
        visitedAgo() {
            if (!this.userCard.users || !this.userCard.users[0]) {
                return;
            }
            return 'visited ' + moment(this.userCard.users[0].visited).fromNow();
        },
        sound() {
            if (!this.userCard.settings || !this.userCard.settings[0]) {
                return;
            }
            return this.userCard.settings[0].sound === 1;
        },
        music() {
            if (!this.userCard.settings || !this.userCard.settings[0]) {
                return;
            }
            return this.userCard.settings[0].music === 1;
        },
        onlineStatus() {
            if (!this.userCard.users || !this.userCard.users[0] || !this.userCard.users[0].visited) {
                return 'white';
            }

            var lastVisitedInteral = (new Date() - new Date(this.userCard.users[0].visited))/60000;
            if (lastVisitedInteral < 15) {
                return 'green-circle';
            } else if (lastVisitedInteral < 120) {
                return 'yellow-circle';
            } else {
                return 'grey-circle';
            }
        },
        abTests() {
            return utils.abTests(this.$route.params.userId, this.gameName);
        }
    },
    methods: {
        getFlagUrl(lang) {
            var images = require.context('../../../assets/flags', false, /\.svg$/);
            if (lang) {
                return images('./' + lang + '.svg');
            }
        }
    }
}
</script>

<style scoped>
.icon-container {
    width: 100px;
    height: 100px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px;
}

.avatar {
    width: 100%;
    height: 100%;
    border-radius: 6%;
}

.game-icon-container {
    position: relative;
    width: 110px;
}

.green-circle {
    background-color: #4cc44c;
}
.grey-circle {
    background-color: #c5d2c5;
}
.yellow-circle {
    background-color: #e8a41b;
}

.status-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid white;
    bottom: 0;
    right: 0;
    position: absolute;
}

.game-icon {
    width: 70px;
    height: 70px;
    display: block;
    left: 8px;
    border-radius: 6%;
}

.sound-icon {
    top: 5px;
    right: 0;
    padding: 1px;
    position: absolute;
    z-index: 1;
    background-color: #e5e5e5;
    border-radius: 4px;
}
.music-icon {
    bottom: 5px;
    right: 0;
    position: absolute;
    z-index: 1;
    background-color: #e5e5e5;
    border-radius: 4px;
}

.header-title-text {
    margin: 0;
    font-size: smaller;
}

.header-title {
    padding: 10px 20px;
    text-align: center;
}

.header-title-text {
    margin-bottom: 0;
    color: black;
    font-size: 18px;
}

.flag-icon {
    width: 22px;
    height: 23px;
    vertical-align: middle;
    margin-left: 4px;
}

.small-text {
    font-size: 0.8rem;
    line-height: 0.5rem;
    padding-bottom: 6px;
    text-align: left;
    color: #5f5f5f;
}

</style>