/**
 * Created by Andrey Popov on 12/11/20.
 */

import {createWebHashHistory, createRouter} from "vue-router";
import Dialogues from "./dialogues.vue";
import UserCard from "./usercard.vue";
import Login from "./login.vue";
import ZenActivator from "./zenactivator.vue"

const routes = [
    {
        path: "/",
        name: "ZenActivator",
        component: ZenActivator
    },
    {
        path: "/folder/:folder",
        name: "Dialogues",
        component: Dialogues
    },
    {
        path: "/folder/:folder/user/:userId",
        name: "UserCard",
        component: UserCard
    },
    {
        path: "/folder/:folder/user/:userId/dialogue/:dialogueId",
        redirect: to => {
            return { name: 'UserCard', params: {folder: to.params.folder, userId: to.params.userId}}
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    }
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL.length === 0 ? '/' : process.env.BASE_URL),
    routes
});

export default router;