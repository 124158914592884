<template>
    <div class="caption-message-body" v-if="message.isCaption" >
        <span>{{ message.message }}</span>
    </div>
    <div class="user-message-body" v-if="!message.isResponse && !message.isCaption" >
        <div class="message-content">
            <div class="message-text" v-on:mouseenter="hover = true" @mouseleave="hover = false">
                <p ref="message-content">{{ message.enTranslation || message.message }}</p>
                <span class="translation" v-if="message.enTranslation && hover">
                    <img class="flag-icon" :title='userLang' :src="getFlagUrl(userLang)"/>{{ message.message }}
                </span>
            </div>
            <div class="message-timestamp">
                {{ formatDate(message.postDate)  + (message.level ? ". Level: " + message.level : "")}}
            </div>
        </div>
    </div>
    <div class="support-message-body" v-if="message.isResponse" >
        <div class="message-content">
            <div class="message-text" v-on:mouseenter="hover = true" @mouseleave="hover = false">
                <span v-if="message.respondingPerson === 'me'" class="message-username"><b>CleverApps support: </b></span>
                <span v-if="message.respondingPerson !== 'me'" class="message-username"><b>{{message.respondingPerson }} (support): </b></span>
                <span v-if="!message.readByUser && (!message.messageMark || message.messageMark.indexOf('takengift') === -1)" class="tool removeIcon" v-on:click="removeMessage(message.id, message.dialogueId)" title="remove message"/>
                <span v-if="!message.readByUser && (!message.messageMark || message.messageMark.indexOf('gift') === -1)" class="tool editIcon" v-on:click="editMessage(message)" title="edit message"/>
                <span v-if="expedition" class="message-expedition">{{ expedition }}</span>
                <p v-if="!message.messageMark || message.messageMark.indexOf('gift') === -1" ref="message-content">
                    {{ message.enTranslation || message.message }}</p>
                <div v-if="message.messageMark && message.messageMark.indexOf('gift') !== -1">
                    <p v-if="progress">{{ progress }}</p>
                    <p v-if="restoreProgress">{{ restoreProgress }}</p>
                    <p v-if="metha">{{ metha }}</p>
                    <div v-if="harvested" class="presents">
                        <div>Game administrators sent special present for You!</div>
                        <div v-for="fruitCode in Object.keys(harvested)">
                            <img class="present-icon" :title='fruitCode' :src="getFruitUrl(fruitCode)"/>
                            <div class="present-amount">x{{ harvested[fruitCode] }}</div>
                        </div>
                    </div>

                    <div v-if="presents">
                        <p v-if="!isHeroesUpgrade">Game administrators sent special present for You!</p>
                        <p v-if="isHeroesUpgrade">Game administrators sent you new Heroes!</p>
                        <div class="presents">
                            <div v-for="present in presents">
                                <p v-if="present.type === 'unit'" class="present-amount"> Unit '{{present.code}}' with stage {{present.stage}}</p>
                                <img class="present-icon" :title='present.type' :src="getPresentUrl(present)"/>
                                <p class="present-amount">x{{ present.amount || 1 }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img v-if="message.messageMark === 'takengift'" class="taken" title="gift taken" src="../../../assets/checkmark_yes.png"/>
                <img v-if="message.messageMark === 'gift'" class="taken" title="gift not taken" src="../../../assets/checkmark_no.png"/>
                <span class="translation" v-if="message.enTranslation && hover">
                    <img class="flag-icon" :src="getFlagUrl(userLang)"/>{{ message.message }}
                </span>
            </div>
            <img v-if="!message.readByUser && !message.isTmp" class="checkmark" title="sent" src="../../../assets/checkmark_1.png"/>
            <img v-if="message.readByUser" class="checkmark" title="read by user" src="../../../assets/checkmark_2.png"/>
            <div class="message-timestamp">
                {{ formatDate(message.postDate) }}
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import utils from "@/utils.js";

export default {
    name: "chatmessage",
    computed: {
        expedition() {
            if (!this.message.messageMark || this.message.messageMark.indexOf('gift') === -1) {
               return false;
            }

            var expedition = JSON.parse(this.message.message).expedition;
            if (expedition && expedition !== "main") {
                return expedition;
            }
        },
        presents() {
            return JSON.parse(this.message.message).presents;
        },
        isHeroesUpgrade() {
            return this.presents && this.presents.length > 0 && this.presents[0].type.indexOf('hl') !== -1;
        },
        harvested() {
            return JSON.parse(this.message.message).harvested;
        },
        progress() {
            var progress = JSON.parse(this.message.message).progress;
            if (progress) {
                var levelNo = (parseInt(progress.episode) || 0) * 15 + progress.level + 1;
                return "Game administrators have changed your progress to level " + levelNo;
            }
            return undefined;
        },
        restoreProgress() {
          var progress = JSON.parse(this.message.message).restoreProgressId;
          if (progress) {
            return "Game administrators have transferred your progress from ID: " + progress;
          }
          return undefined;
        },
        metha() {
            var metha = JSON.parse(this.message.message).metha;
            if (metha) {
                if (metha.farm) {
                    return "Game administrators have changed your building number to " + (metha.farm.building) + ", quest number to " + (metha.farm.quest);
                } else if (metha.simple) {
                    return "Game administrators have changed your background number to " + (metha.simple.current);
                }
            }

            return undefined;
        },
        gameName() {
            return this.$route.params.folder.split(':')[1].split('_')[0].toLowerCase();
        }
    },
    props: {
        message: {
            type: Object,
            required: true
        },
        userLang: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            hover: false,
        }
    },
    emits: ['editMessage'],
    methods: {
        formatDate(str) {
            return moment(str).format('DD MMM HH:mm');
        },
        getPresentUrl(present) {
            return utils.getRewardUrl(this.gameName, present.type);
        },
        getFruitUrl(code) {
            return utils.getUnitUrl(this.gameName, code);
        },
        getFlagUrl(lang) {
            return utils.getFlagUrl(lang);
        },
        removeMessage(messageId) {
            this.$store.dispatch('removeMessage', {
                id: messageId,
                userid: this.$route.params.userId,
                dialogueid: this.$store.state.dialogueId
            });
        },
        editMessage(message) {
            this.$emit('editMessage', message.message, message.id);
        }
    }
}
</script>

<style scoped>

.caption-message-body {
    width: 99%;
    text-align: center;
    border-bottom: 1px solid #a5a5a5;
    line-height: 0.1em;
    margin: 10px 0 20px;
    color: #a5a5a5;
}

.caption-message-body span {
    background:#fff;
    padding:0 10px;
}

.user-message-body {
    display: flex;
    align-items: flex-end;
    padding-left: 20px
}

.message-username {
    font-size: 12px;
    margin-left: 2px;
}

.message-expedition {
    float: right;
    font-size: 12px;
    font-weight: bold;
    margin-left: auto;
}

.user-message-body .message-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    flex-grow: 1;
}

.user-message-body .message-timestamp {
    padding: 2px 7px;
    border-radius: 15px;
    margin: 0;
    max-width: 50%;
    overflow-wrap: break-word;
    text-align: left;
    font-size: 10px;
    color: #6a6666;
    width: 100%;
    display: flex;
    align-items: center;
}

.user-message-body .message-text {
    background: #fff;
    padding: 2px 10px;
    line-height: 20px;
    border-radius: 15px;
    margin: 5px 0 5px 0;
    max-width: 90%;
    overflow-wrap: break-word;
    text-align: left;
    white-space: pre-wrap;
    border-bottom-left-radius: 0px;
    word-break: break-word;
    background-color: #fdf7dd;
}

.caption-message-body .message-text {
    padding: 0px 10px;
    line-height: 0px;
    margin: 0;
}

.support-message-body {
    display: flex;
    align-items: flex-end;
    padding-right: 20px;
    justify-content: flex-end;
}

.support-message-body .message-content {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
    flex-grow: 1;
}

.support-message-body .message-text {
    background: #fff;
    padding: 2px 10px;
    line-height: 20px;
    border-radius: 15px;
    margin: 5px 0 5px 0;
    max-width: 90%;
    overflow-wrap: break-word;
    text-align: left;
    white-space: pre-wrap;
    border-bottom-right-radius: 0px;
    word-break: break-word;
    background-color: rgba(85, 190, 237, 0.5);
}

.message-text p {
    margin: 2px;
}

.support-message-body .message-timestamp {
    padding: 2px 7px;
    border-radius: 15px;
    margin: 0;
    max-width: 50%;
    width: 80px;
    overflow-wrap: break-word;
    text-align: right;
    font-size: 10px;
    color: #6a6666;
    display: flex;
    align-items: flex-end;
}

.checkmark {
    margin-bottom: -12px;
    width: 12px;
    height: 10px;
}

.tool {
    float: right;
    margin-left: 2px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    font-weight: 400;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.tool:hover {
    font-weight: 1000;
}
.removeIcon {
    margin-top: 4px;
}
.removeIcon::before {
    content: '\0000d7';
}
.editIcon {
    margin-left: 30px;
    margin-top: 2px;
}
.editIcon::before {
    content: '\00270E';
}

.presents {
    display: inline-flex;
}

.present-icon {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-left: 15px;
}

.present-amount {
    display: inline-block;
    font-size: large;
    margin-bottom: 12px;
}

.taken {
    float: right;
    margin-top: -5px;
}

.flag-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    vertical-align: middle;
}

.translation {
    font-size: small;
}

</style>
